<template>
  <div>
    <MasterHeader />
    <div class="container container-medium">
      <form @submit.prevent="dispatchNewCompany()">
        <div class="row">
          <div class="col-12">
            <router-link
              :to="'/companies/' + $route.params.id"
              class="btn btn-sm btn-link"
              ><i class="fas fa-angle-left"></i> Back to your
              company</router-link
            >
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="mt-4">
              <h2>Edit Company Details</h2>
              <div class="form-row">
                <div class="form-group col-12">
                  <label>Full company name*</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="Enter company name"
                    v-model="companyDetails.companyName"
                    required
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-12 col-md-7">
                  <label>Address*</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter address"
                    v-model="companyDetails.billingStreetAddress"
                    required
                  />
                </div>
                <div class="form-group col-12 col-md-5">
                  <label>Company identification number (CVR)*</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter identification number"
                    v-model="companyDetails.companyIdentificationNumber"
                    required
                  />
                </div>

                <div class="form-group col-12 col-md-4">
                  <label>Post code*</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Post code"
                    v-model="companyDetails.billingPostCode"
                    required
                  />
                </div>

                <div class="form-group col-12 col-md-4">
                  <label>City*</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter City"
                    v-model="companyDetails.billingCity"
                    required
                  />
                </div>

                <div class="form-group col-12 col-md-4">
                  <label>Country*</label>
                  <select
                    class="form-control"
                    v-model="companyDetails.billingCountry"
                  >
                    <option v-for="(country, index) in countries" :key="index">
                      {{ country.country }}
                    </option>
                  </select>
                </div>

                <div class="form-group col-12">
                  <label>Short description*</label>                  
                  <small class="text-muted test"
                    >The students you engage with will see this text</small
                  >                 
                  <div
                    :class="{
                      'limit-exceeded': characters > 400,
                    }"
                  >
                    <textarea
                      v-model="companyDetails.companyDescription"
                      class="form-control"
                      placeholder="Example; Microsoft Corporation is a technology company. The Company develops and supports a range of software products, services, devices, and solutions. The Company's segments include Productivity and Business Processes, Intelligent Cloud, and More Personal Computing."
                      rows="5"
                      :class="{
                        textarea: characters > 400,
                      }"
                      required
                    ></textarea>
                  </div>
                  <div class="charactor-counter">
                    Characters {{ characters }}/400
                  </div>
                </div>      
                         
                <div class="form-group col-12 col-md-6">
                  <label>Website*</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Begin with http:// or https:// or www."
                    v-model="companyDetails.companyURL"  
                    @blur="isUrlValid"                                     
                    required
                  />                  
                  <div v-if="errorMessage" class="errorMessage">
                    {{errorMessage}}
                  </div>
                </div>
                <div class="form-group col-12 col-md-6">
                  <label>Company type*</label>

                  <multiselect
                    v-model="companyDetails.companyType"
                    :options="filterCompanyTypeByCountry"
                    :showLabels="false"
                    :searchable="false"
                    :allow-empty="false"
                    deselect-label="Can't remove this value"
                    placeholder="Select one"
                    track-by="name"
                    label="name"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">{{
                      option.name
                    }}</template>
                  </multiselect>                 
                </div>
              
                <div class="form-group col-12 col-md-4">
                  <label>Linkedin Public URL</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="linkedin.com/company/"
                    v-model="companyDetails.companyLinkedinUrl" 
                    pattern="^https://[a-z]{2,3}[.]linkedin[.]com/.*$"                  
                    
                  />
                 
                </div>
                <div class="form-group col-12 col-md-4">
                  <label>Industry*</label>
                  <multiselect
                    v-model="companyDetails.industry"
                    :options="filterIndustryTypeByCountry"
                    :showLabels="false"
                    :searchable="true"
                    :allow-empty="false"
                    deselect-label="Can't remove this value"
                    placeholder="Select Industry"
                    track-by="name"
                    label="name"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">{{
                      option.name
                    }}</template>
                  </multiselect>                  
                </div>
                <div class="form-group col-12 col-md-4">
                  <label>Company Size*</label>
                  <multiselect
                    v-model="companyDetails.companySize"
                    :options="filterNoOfEmployeesByCountry"
                    :showLabels="false"
                    :searchable="false"
                    :allow-empty="false"
                    deselect-label="Can't remove this value"
                    placeholder="Select company size"
                    track-by="name"
                    label="name"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">{{
                      option.name
                    }}</template>
                  </multiselect>                 
                </div>

                <div class="form-group col-12">
                  <div>
                    <label>Upload Logo</label>

                    <small class="text-muted test"
                      >250x150px recommended, JPGs, JPEGs, and PNGs
                      supported</small
                    >
                    <div class="col-6 pl-0">
                      <button
                        class="btn px-4"
                        style="border:1px solid #5A2871; color:#5A2871"
                        @click.prevent="onPickFile"
                      >
                        Upload
                      </button>
                      <button
                        v-if="imageUrl"
                        class="btn btn-primary px-2 ml-3"
                        @click.prevent="cropImage"
                      >
                        Save
                      </button>
                      <div
                        v-if="companyDetails.imageUrl"
                        class="image-container my-2"
                      >
                        <img
                          :src="companyDetails.imageUrl"
                          style="width:250px"
                        />
                      </div>
                    </div>                   
                    <input
                      type="file"
                      style="display: none"
                      ref="fileInput"
                      accept="image/*"
                      @change="onFilePicked"
                    />
                    <!-- @ready="autoCropImage" -->
                    <div v-if="imageUrl" class="img-container">
                      <vue-cropper
                        ref="cropper"
                        :src="imageUrl"                                                
                        :aspect-ratio="16 / 9"
                        preview=".preview"
                        class="vue-cropper"
                        :background="false"
                        :auto-crop-area="1"
                      />
                    </div>
                  </div>
                  <!--subscription  -->
                  <div v-if="!user.subscription">
                    <router-link
                      to="/subscription/"
                      class="btn subscription pl-0"
                      >Choose Subscription Plan
                    </router-link>
                  </div>
                  <div
                    v-if="user.subscription"
                    class="subscription-details mt-2"
                  >
                    <h4 class="subscription">Subscription</h4>

                    <h4 v-if="isBeta" class="subscription-plan">
                      Free {{ user.subscriptionPlan }} Program
                    </h4>                    
                    <p>With the free Beta Program you get:</p>
                    <p>• ∞ Thesis collaboration pr. semester</p>
                    <p>• Curated thesis topics</p>
                    <p>• Access to all thesis groups</p>
                    <p>• Pre-collaboration interview option</p>
                    <p>• No hiring fee post-collaboration</p>
                    <p>
                      • You will not be charged for signing up to the beta
                      program - ever.
                    </p>
                    <p>
                      • Available for a limited time, while we are in 'beta'
                      mode. At that time, you can choose if you <br />
                      want to continue with a paid subscription.
                    </p>
                  </div>
                </div>

                <div class="ml-2">
                  <small class="text-muted">*Required</small>
                </div>
              </div>
            </div>            
            <div class="form-group text-right">
              <button class="btn btn-primary">Save</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { db } from "../../firebase";
import MasterHeader from "@/components/MasterHeader.vue";
import json_countries from "@/json/countries.json";
import json_typeofcompany from "@/json/typeofcompany.json";
import json_industries from "@/json/industries.json";
import json_noofemployees from "@/json/numberofemployees.json";
import Multiselect from "vue-multiselect";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { base64ToString } from "../../utils/utils";

// import { mapState } from 'vuex isValid: "",'
export default {
  data() {
    return {
      imageUrl: "",
      companyDetails: "",
      countries: json_countries,
      errorMessage: "",     
      isValid:"",
      isImageCropped:null,
      isImageSelected:null,
     
    };
  },
    created() {
    // const userType = localStorage.getItem('i_am')
    // const userVerified = localStorage.getItem('u-verified')
                let userType = localStorage.getItem('i_am')
    userType = base64ToString(userType)
    let userVerified = localStorage.getItem('u-verified')
    if (userVerified == 0 && userType == 'student') {
      this.$router.push('/user/student-welcome')
    }
    if (userVerified == 0 && userType == 'professional') {
      this.$router.push('/user/welcome')
    }
  },
  components: {
    MasterHeader,
    Multiselect,
    VueCropper,
  },
  

  computed: {
    ...mapState(["user"]),

    billingCountry: function() {
      var result = this.companyDetails.billingCountry;
      return result;
    },
    filterCompanyTypeByCountry: function() {
      var vm = this;

      var result = [];      
      if (typeof json_typeofcompany !== typeof undefined) {
        let types = json_typeofcompany.filter(
          (obj) => obj.Country == vm.companyDetails.billingCountry
        );
        if (types.length != 0) {
          result = types[0].jobs;
        }
      }
      //  if(result[0].length==1){
      //    result=result[0].jobs
      //  }

      return result;
    },
    filterIndustryTypeByCountry: function() {
      var vm = this;

      var result = [];

      if (typeof json_industries !== typeof undefined) {
        let industries = json_industries.filter(
          (obj) => obj.Country == vm.companyDetails.billingCountry
        );
        if (industries.length != 0) {
          result = industries[0].jobs;
        }
      }
      return result;
    },
    filterNoOfEmployeesByCountry: function() {
      var vm = this;

      var result = [];

      if (typeof json_noofemployees !== typeof undefined) {
        let noOfEmployees = json_noofemployees.filter(
          (obj) => obj.Country == vm.companyDetails.billingCountry
        );
        if (noOfEmployees.length != 0) {
          result = noOfEmployees[0].jobs;
        }
      }
      return result;
    },
    characters: function() {
      var res = 0;
      if (typeof this.companyDetails.description != "undefined") {
        res = this.companyDetails.description.length;
      }
      return res;
    },
    isBeta: function() {
      return this.user.subscriptionPlan === "Beta";
    },
   
  },  

  methods: {
    
    isUrlValid() {
      const url = this.companyDetails.companyURL;      
     //eslint-disable-next-line
      var res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,50}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
      
      if (res == null) {        
        this.errorMessage="please enter a valid url"
        return this.isValid=false;
      } else {       
        this.errorMessage=""
        return this.isValid=true;
      }
    },
   
    onPickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      this.isImageSelected=true;
      const files = event.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Please add a valid file!");
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", (e) => {
        this.imageUrl = fileReader.result;       
        // this.$refs.cropper.replace(e.target.result);
      });
      fileReader.readAsDataURL(files[0]);
      this.companyDetails.image = files[0];
      
    },
    cropImage() {
      this.isImageCropped=true;
      // get image data for post processing, e.g. upload or setting image src      
      this.companyDetails.image = this.$refs.cropper
        .getCroppedCanvas()
        .toDataURL();      
    },
    // autoCropImage() {
    //  console.log("auto crop")
    //   this.cropImage();
    // },
    async dispatchNewCompany() {     
      
      await this.isUrlValid()
       if (this.isValid) {
        const data = {
          id: this.$route.params.id,
          companyDetails: this.companyDetails,
          user: this.user,
        };
        if(this.isImageSelected){
          // console.log("isImageSelected", this.isImageSelected, this.isImageCropped)
          if(!this.isImageCropped){
            this.cropImage()            
          }
        }        
        this.$store.dispatch("updateCompany", data);
      }
    },
  },
  firestore() {
    return {
      companyDetails: db.collection("companies").doc(this.$route.params.id),
    };
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.test {
  display: block;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}
.charactor-counter {
  float: right;
  width: 25%;
  text-align: right;
  padding-right: 5px;
  font-size: 12px;
  color: rgb(173, 173, 173);
}
.errorMessage{
  color: #cc0033;  
  font-size: 12px; 
  margin: 2px 0 0;
  padding-left: 5px;
}
.limit-exceeded {
  border: 1px solid red;
}
.form-control-file {
  width: 30%;
}
.textarea:focus {
  border: none;
}
.subscription {
  font-weight: 500;
  color: #5A2871;
  font-size: 14px;
}
.subscription-details {
  font-size: 14px;
  /* line-height:1; */
}
.subscription-details p {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}
.subscription-plan {
  color: #828282;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 1.2rem;
}
input[type="file"] {
  display: none;
  color: rgba(0, 0, 0, 0);
}
.vue-cropper {
  height: 300px;
  width: 300px;
  margin: 20px 0 20px 0;
  padding: 10px;
}
</style>
<style>
.multiselect__input{
  color:#5A2871;
}
.multiselect__option--highlight {
  background: #bd1a77;
  outline: none;
  color: #fff;
}
.multiselect {
  color: #5A2871;
  font-size: 14px;
  background: #fff;
}
.multiselect,
.multiselect__single {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}
.multiselect__single {
  margin-bottom: 0px;
}
.multiselect__tags {
  font-family: "Poppins", sans-serif;
  color: #5A2871;
  font-size: 14px;
  border: 1px solid #dfd6df !important;
  border-radius: 4px;
  padding: 6px 20px 0 8px;
  min-height: 35px !important;
}
.multiselect__option--selected.multiselect__option--highlight{
  background-color:#5A2871;
}
.multiselect__placeholder {
  margin-bottom: 4px;  
}
</style>
